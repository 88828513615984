import axios from 'axios'
import useSWR, { useSWRConfig } from 'swr'
import useSWRInfinite from 'swr/infinite'
import { useQuery } from 'context/QueryProvider'

interface SwrOptions {
  onSuccess?: (data: any) => void
  onError?: (error: any) => void
}

export default function useSWRCustom(url: string | null, options?: SwrOptions) {
  const { client } = useQuery()

  const fetcher = async (URL: string) => {
    const resp = await axios.get(URL, { headers: client.headers })
    return resp?.data
  }

  const { data, error, isValidating, mutate } = useSWR(url, fetcher, options)

  return {
    data: data?.['hydra:member'] ?? data,
    error,
    isValidating,
    mutate,
    total: (data?.['hydra:totalItems'] ?? data?.nbResults) ?? null,
  }
}

export function useSWRInfiniteScroll(url: string | null, options?: SwrOptions) {
  const fetcher = async (URL: string) => {
    const resp = await axios.get(URL, { headers: client.headers })
    return resp?.data
  }
  const { client } = useQuery()

  const {
    data,
    mutate,
    size,
    setSize,
    isValidating,
  }: any = useSWRInfinite(
    index => `${url}&page=${
      index + 1
    }`,
    fetcher,
    options,
  )

  const lastPageURL = String(data?.[0]?.['hydra:view']?.['hydra:last'])
  const lastPage = new URLSearchParams(lastPageURL.split('?')[1]).get('page')

  return {
    data: data?.flatMap((d: any) => d['hydra:member']) ?? data,
    mutate,
    size,
    setSize,
    isValidating,
    total: (data?.[0]?.['hydra:totalItems'] ?? data?.nbResults) ?? null,
    lastPage: lastPage ?? null,
  }
}

export {
  useSWRConfig,
}


